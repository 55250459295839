<template>
    <v-container v-if="!halted" class="amp-module-page wide">
        <video-autofill
            ref="autofill"
            class="mb-4 pt-0"
            :modules="modules"
            :loading="isLoading"
        />
        <a-form ref="form" :auto-submit="!isModuleBusy" @auto-submit="autoSave">
            <v-card
                class="pa-4"
                :class="{
                    'pa-5 px-6': $vuetify.breakpoint.mdAndUp
                }"
            >
                <v-row v-if="hasPublishIssues">
                    <v-col>
                        <a-alert
                            v-for="(issue, i) in publishIssues"
                            :key="i"
                            :message="issue.message"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-text-input
                            ref="title"
                            v-model="video.title"
                            :loading="isLoading"
                            label="Title"
                            rules="required|max:100"
                            rows="1"
                            auto-grow
                            textarea
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-content-editor
                            ref="summary"
                            v-model="video.summary"
                            :loading="isLoading"
                            label="Summary"
                            :counter-value="getWordsCounter(45)"
                            :no-first-person="false"
                            rules="required|words:0,45"
                            one-line
                        />
                    </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.smAndDown">
                    <v-col class="py-0 col-sm-12">
                        <a-slideshow
                            ref="slideshow"
                            label="Preview"
                            :template="video.video_template"
                            :active="slide"
                            @slide="sync"
                            @zoom="handleFullScreen"
                        />
                        <amp-validation
                            :input="$refs.slideshow"
                            parse
                            limit="5"
                            class="pointing-validation mt-4"
                            @click.native="navigateToSlide"
                        />
                        <a-template-selector
                            class="text-right"
                            :templates="templates"
                            :active-template="video.video_template"
                            :loading="isBusy"
                            @change="changeTemplate"
                        />
                    </v-col>
                </v-row>
                <v-row class="video-container">
                    <v-col class="py-0 col-md-6 col-lg-6 col-sm-12">
                        <media-form
                            ref="mediaForm"
                            :template="video.video_template"
                            entity="slide"
                            entity-icon="image"
                            class="pa-0"
                            @activate="sync"
                        />
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="py-0 col-md-6 col-lg-6"
                    >
                        <a-template-selector
                            class="text-right"
                            :templates="templates"
                            :active-template="video.video_template"
                            @change="changeTemplate"
                        />
                        <sticky-media
                            relative-element-selector=".video-container"
                            :offset="{ top: 90, bottom: 90 }"
                            :enabled="
                                !isFullScreen && !$vuetify.breakpoint.mobile
                            "
                        >
                            <a-slideshow
                                ref="slideshow"
                                label="Preview"
                                :template="video.video_template"
                                :active="slide"
                                @slide="sync"
                                @zoom="handleFullScreen"
                            />
                            <amp-validation
                                :input="$refs.slideshow"
                                parse
                                limit="5"
                                class="pointing-validation mt-4"
                                @click.native="navigateToSlide"
                            />
                            <a-template-selector
                                class="text-right"
                                :templates="templates"
                                :active-template="video.video_template"
                                :loading="isBusy"
                                @change="changeTemplate"
                            />
                        </sticky-media>
                    </v-col>
                </v-row>
            </v-card>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { Endpoint, MediaModuleEndpoint, WordsCounter } from '@/mixins';
import { InjectReactive, ProvideReactive, Watch } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { AAlert } from '@/components/AAlert';
import { ASlideshow, MediaForm, StickyMedia } from '@/components/ASlideshow';
import { ATemplateSelector } from '@/components/ATemplateSelector';
import { VideoAutofill } from '@/components/VideoAutofill';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules } from '@/types/Announcement';
import type { SlideshowTemplate } from '@/types/Slideshow';
import type { Video } from '@/types/Video';
import type { NavigationGuardNext, Route } from 'vue-router';
import { ModuleLink } from '@/types/ModuleLink';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AForm,
        AAlert,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        ASlideshow,
        ATemplateSelector,
        ATextInput,
        AContentEditor,
        MediaForm,
        StickyMedia,
        VideoAutofill
    }
})
export default class Slideshow extends mixins(
    Endpoint,
    MediaModuleEndpoint,
    WordsCounter
) {
    $refs!: {
        autofill: InstanceType<typeof VideoAutofill>;
        form: InstanceType<typeof AForm>;
        mediaForm: InstanceType<typeof MediaForm>;
        title: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        slideshow: InstanceType<typeof ASlideshow>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'isModuleBusy',
        default() {
            return false;
        }
    })
    isModuleBusy!: boolean;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    @Watch('slide')
    onSlideChange() {
        this.syncForm(this.slide);
    }

    @ProvideReactive()
    get user_id() {
        return this.video.user_id;
    }

    video: Partial<Video> = {};

    endpoint = '/slideshows/edit';

    slide = 0;

    isSaving = false;

    isFullScreen = false;

    halted = false;

    templates: SlideshowTemplate[] = [];

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    get moduleId() {
        return this.modules?.slideshow_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get template() {
        return (
            this.video.video_template || {
                video_boxes: [],
                width: 0,
                height: 0
            }
        );
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get hasPublishIssues() {
        return this.publishIssues.length > 0;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-slideshow`
        );
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/slideshow`;
    }

    get isBusy() {
        return this.isSaving || this.isLoading;
    }

    get isInitialized() {
        return Boolean(this.video.id);
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: {
        video: Video;
        slideshow_templates: SlideshowTemplate[];
        meta: { success: boolean };
    }) {
        if (data.video) {
            return this.commit(data);
        } else {
            this.review();
        }
    }

    async commit(data: {
        video: Video;
        slideshow_templates: SlideshowTemplate[];
        meta: { success: boolean };
    }) {
        if (data.video) {
            this.softCommit(data.video);

            this.templates = data.slideshow_templates;

            this.protectRoute();

            this.emitLinks();
        }

        await this.setSaved();

        return data;
    }

    softCommit(data: Video) {
        if (this.isInitialized) {
            const softProperties: Array<keyof Video> = [
                'is_editable',
                'is_live',
                'is_publishable',
                'status',
                'status_string'
            ];

            (Object.keys(data) as Array<keyof Video>)
                .filter(key => softProperties.includes(key))
                .forEach(key => {
                    this.$set(this.video, key, data[key]);
                });
        } else {
            this.video = data;
        }
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        if (this.halted) {
            return next();
        }

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => next());
        } else {
            return next();
        }
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    save(foreground = true) {
        this.setSaving();

        return this.setData()
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async autoSave() {
        return this.setData().catch(error => {
            if (!error.isIntercepted) {
                this.$store.dispatch('notification/error', error);
            }
        });
    }

    async setData() {
        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => data)
            .then(({ data }) => {
                if (!data?.meta?.success) {
                    throw new Error(
                        'Unable to save Slideshow. Please check the form for errors.'
                    );
                }

                return data;
            })
            .then(data => this.commit(data));
    }

    getDataToSave() {
        return {
            id: this.video.id,
            video_template_id: this.video.video_template_id,
            title: this.video.title,
            summary: this.video.summary,
            link: null,
            media_resources: this.collectMediaResources(
                this.video.video_template
            ),
            video_captions: this.collectVideoCaptions(this.video.video_template)
        };
    }

    navigateToSlide(e: PointerEvent) {
        const slide = (e.target as HTMLElement).dataset.slide;

        if (slide) {
            this.sync(Number(slide));
        }
    }

    sync(slide: number) {
        this.slide = slide;
    }

    syncForm(slide: number) {
        this.$nextTick(() => {
            this.$refs.mediaForm.scrollToGroup(slide);
        });
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    async changeTemplate(id: number) {
        if (this.$refs.form?.isDirty) {
            await this.save(false);
        }

        this.setSaving();
        // notify server about new template
        await this.$http.post(this.sourceUrl, {
            id: this.video.id,
            change_template: id
        });

        // regenerate slideshow using new template
        await this.$http.get(
            `${this.endpoint}?announcement_id=${this.announcementId}&new_template=${id}`
        );

        this.setSaving(false);
        // reload entire Amp since slideshow receives new ID
        this.reload();
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-slideshow`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        return isValid;
    }

    review() {
        this.$router.push(this.reviewLink);
    }

    handleFullScreen(isFullScreen: boolean) {
        this.isFullScreen = isFullScreen;
    }

    protectRoute() {
        if (!this.video.is_editable) {
            this.halt();

            this.$nextTick(() => {
                this.review();
            });
        }
    }

    emitLinks() {
        this.$emit('links', this.link);
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => this.revalidate())
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/info',
            'Slideshow saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Slideshow. Please check the form for errors.'
        );
    }

    halt(halt: boolean = true) {
        this.halted = halt;
    }

    reload() {
        // resets isInitialized flag, so new data can be applied
        this.video.id = 0;

        this.$emit('reload');
    }
}
</script>
